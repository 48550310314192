<template>
  <div class="company">
    <van-form>
      <van-row>
        <van-cell title="" is-link value="返回" @click="$router.back()" />
      </van-row>
      <div class="title">资料已提交，请完成支付</div>
      <div class="servie">服务费： ￥{{ membershipFee }}元</div>
      <van-row>
        <van-col span="6"></van-col>
        <van-col span="6" style="text-align: center; line-height: 50px;">
          <van-button type="info" size="small" @click="toPay()">立即支付</van-button>
        </van-col>
        <van-col span="6" style="text-align: center; line-height: 50px;">
          <van-button type="default" size="small" @click="toEdit()">返回修改</van-button>
        </van-col>
        <van-col span="6"></van-col>
      </van-row>
    </van-form>
    <div class="bottom"></div>
  </div>
</template>

<script>
import { getCompanyStatus, toPay } from '@/network/company'
import { mapState } from 'vuex'
export default {
  name: 'apply',
  data() {
    return {
      orderSn: null,
      membershipFee: 0
    }
  },
  created() {
    this.getMyStatus()
  },
  computed: {
    ...mapState(['baseURL'])
  },
  methods: {
    getMyStatus() {
      getCompanyStatus().then((res) => {
        if (res.data) {
          this.membershipFee = res.data.membership_fee
          if (res.data.store && res.data.store.order_sn) {
            this.orderSn = res.data.store.order_sn
          }
        }
      })
    },
    toPay() {
      if (this.orderSn == null) {
        this.$toast('获取订单信息出错')
        return false
      }
      toPay(this.orderSn).then(() => {
        // const retUrl = 'https://h5.sch888.cn/'
        const url = this.baseURL + '/api/Wxh5pay/?type=1&master_order_sn=' + this.orderSn
        console.log(url)
        // const url = res.data.h5_url + '&redirect_url=' + urlencode(retUrl)
        location.replace(url)
      })
    },
    toEdit() {
      this.$router.push('/companyApply')
    }
  }
}
</script>

<style scoped>
.company .title {
  margin-top: 80px;
  text-align: center;
  font-size: 18px;
}
.company .servie {
  margin: 40px;
  text-align: center;
  font-size: 16px;
}
.company .bottom {
  margin: 40px;
}
</style>
